import {catchAsyncStacktrace, asyncStacktrace} from 'auto-trace';

export default function(sessionIsValid) {
  return Promise.all([SystemJS.import('cp-client-auth!sofe'), SystemJS.import('rxjs'), SystemJS.import('rxjs/operators')])
    .then(values => {
      const [authModule, rxjs, operators] = values;

      // We always need to subscribe, even if the user isn't logged in
      authModule.default.getTenantAsObservable().subscribe(
        tenant => {
          window.tenant = tenant;

          if (!window.tenantTime) {
            window.tenantTime = new Date().getTime() - window.appLoaderInitialTime;
          }
        },
        catchAsyncStacktrace()
      );

      const { pipe, of, throwError } = rxjs
      const { first, catchError } = operators

      if (sessionIsValid) {
        // In the case that the user is logged in we need to return a proise
        // that will eventually yield the user object as our BlockingPromise
        return authModule.default.getTenantAsObservable().pipe(
          first(),
          catchError(asyncStacktrace(err => {
            if (err.status === 401) {
              // User might not be logged in
              return of({noUserYet: true})
            } else {
              return throwError(err)
            }
          })),
        ).toPromise()
      } else {
        // If the user isn't logged in we need to return an immediately resolved
        // promise as the blockingPromise so that we can continue onward and
        // eventually call SingleSpa.start() so the login page can render
        return Promise.resolve()
      }

    })
}

function tenantIsNotLoggedIn() {
  window.tenantTime = new Date().getTime() - window.appLoaderInitialTime;
  Object.defineProperty(window, 'tenant', {
    get() {
      throw new Error(`The user is not logged in. Please do not try to access window.tenant`);
    },
    set() {
      throw new Error(`Cannot set tenant without calling Object.defineProperty`);
    },
    configurable: true,
  });

  // The rest of app loader bootstrap process continues, just with a falsy tenant object
  return Promise.resolve(null);
}
