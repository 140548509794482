import {asyncJs} from './async-resources.bootstrap.js';
import {syncStacktrace} from 'auto-trace'

export default function bootstrapGAPI() {
  return SystemJS.import('canopy-urls!sofe')
    .then(canopyUrls => {
      canopyUrls = canopyUrls.default;

      return new Promise((resolve, reject) => {
        asyncJs(`https://apis.google.com/js/client.js`, () => {
          window.gapi.load('auth2', () => {
            Promise.all([SystemJS.import('fetcher!sofe'), SystemJS.import('cp-client-auth!sofe'), SystemJS.import('rxjs'), SystemJS.import('rxjs/operators')])
              .then(waitForLoggedInUserAndTenant)
              .then((results) => {
                // because of newspaper style I can't rely on the closure within the initializeGoogleClientId function
                // so I'm passing in canopyUrls, fetcher, and the original promises resolve method
                return initializeGoogleClientId(canopyUrls, results[2].fetcher, resolve, reject)
              })
              .catch(err => {
                reject(syncStacktrace(err))
              })
          });
        });
      });
    });
}

function waitForLoggedInUserAndTenant (values) {
    const [fetcher, authModule, {pipe}, {first}] = values
    return Promise.all([
      authModule.default.getLoggedInUserAsObservable().pipe(first()).toPromise(),
      authModule.default.getTenantAsObservable().pipe(first()).toPromise(),
      Promise.resolve({
        fetcher, authModule
      })
    ])
}

function initializeGoogleClientId (canopyUrls, fetcher, resolve, reject) {
  // canopyUrls, fetcher, and the original promise's resolution must be passed in as arguments
  fetcher.fetchAsObservable(`${canopyUrls.getAPIUrl()}/google-client-id`, {passThrough401: true})
    .subscribe(
      data => {
        let client_id = data['google-client-id'];
        /* The API is weird and has {"google-client-id": {"google-client-id": "the_id"}} even though it should only be one level deep.
         * Jesse might fix this at some point but I decided just to be defensive about it so we don't have to wait for him to fix it.
         */
        client_id = typeof client_id === 'object' ? client_id['google-client-id'] : client_id;

        if (typeof client_id !== 'string') {
          reject(Error(`Communication service api for google client id did not return a valid google client id. See api gateway's /google-client-id endpoint`));
        }

        try {
          window.gapi.auth2.init({
            scope: 'https://www.googleapis.com/auth/userinfo.email',
            client_id,
            fetch_basic_profile: false,
          })
          /* You can't call .catch(), but you can call .then() with two args.
           * See https://developers.google.com/api-client-library/javascript/reference/referencedocs
           */
            .then(
              () => {
                resolve();
              },
              err => {
                /* The child applications are responsible for handling this situation and messaging. Usually this seems
                 * to be caused by the issue described in https://github.com/google/google-api-javascript-client/issues/260
                 */
                window.gapiInitializationError = err;
                console.error(`Error occurred initializing gapi. This may impact connecting Google accounts to Canopy:`);
                console.error(err);
                // Since child apps are responsible for handling this, we don't reject the app loader nonBlockingPromises.
                resolve();
              }
            );
        } catch(err) {
          reject(syncStacktrace(err))
        }
      },
      err => {
        if (err.status === 401) {
          resolve();
        } else if (err.message === 'cancelled') {
          // Safari 12.1 is cancelling this subscription upon login (when the login page is being refreshed)
          resolve();
        } else {
          reject(syncStacktrace(err))
        }
      }
    );
}
