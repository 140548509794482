import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace';

export default function setupRetain() {
  return Promise.all([
    SystemJS.import('canopy-urls!sofe'),
    SystemJS.import('cp-client-auth!sofe'),
    SystemJS.import('rxjs'),
    SystemJS.import('sentry-error-logging!sofe'),
  ])
    .then(values => {
      const [urlsModule, authModule, rxjs, sentryErrorLoggingUI] = values;
      const env = urlsModule.default.getEnvironment();

      if (
        !localStorage.getItem('no-product-tracking') &&
        (env === urlsModule.default.PRODUCTION_ENVIRONMENT ||
          env === urlsModule.default.STAGING_ENVIRONMENT ||
          env === urlsModule.default.INTEGRATION_ENVIRONMENT)
      ) {
        rxjs
          .zip(authModule.default.getLoggedInUserAsObservable(), authModule.default.getTenantAsObservable())
          .subscribe(
            values => {
              try {
                const [loggedInUser, tenant] = values;
                if (loggedInUser.role === 'TeamMember' && !loggedInUser.permissions.client) {
                  (function (i, s, o, g, r, a, m) {
                    i[o] =
                      i[o] ||
                      function () {
                        (i[o].q = i[o].q || []).push(arguments);
                      };
                    a = s.createElement(g);
                    m = s.getElementsByTagName(g)[0];
                    a.async = 1;
                    a.src = r + '?auth=ee6b73c4b3b2fa91b634192219c1f519';
                    m.parentNode.insertBefore(a, m);
                  })(window, document, 'profitwell', 'script', 'https://public.profitwell.com/js/profitwell.js');

                  window.profitwell('start', {
                    auth_token: 'ee6b73c4b3b2fa91b634192219c1f519',
                    user_email: loggedInUser.email,
                    user_id: tenant.zuora_id,
                  });
                }
              } catch (e) {
                sentryErrorLoggingUI.captureException(e);
              }
            },
            asyncStacktrace(err => {
              err.showToast = false;
              catchSyncStacktrace(err);
            })
          );
      }
    })
    .catch(
      asyncStacktrace(err => {
        err.showToast = false;
        catchSyncStacktrace(err);
      })
    );
}
