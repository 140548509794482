import styles from './app-install.styles.css';

export const showAppInstall = async function (event) {
  const React = await SystemJS.import('react');
  const ReactDOM = await SystemJS.import('react-dom');
  const { first } = await SystemJS.import('rxjs/operators');

  const auth = await SystemJS.import('cp-client-auth!sofe');
  const { CprButton, CprIcon } = await SystemJS.import('canopy-styleguide!sofe');

  const user = await auth.default.getLoggedInUserAsObservable()
    .pipe(first()).toPromise();

  function AppInstaller() {
    return <div className={styles.root}>
      <div className={styles.content}>
        <CprButton onClick={() => {
          unmount();
        }} className={styles.close} actionType="unstyled">
          <CprIcon name="close-large" size="48" />
        </CprButton>
        <span>The Canopy experience has now been optimized for mobile.</span>
        <CprButton onClick={() => {
          try {
            event.prompt();
          } catch (error) {
            // intentionally swallow error
          }
          unmount();
        }} className={styles.action} actionType="unstyled">GET</CprButton>
      </div>
      <div className={styles.bar}></div>
    </div>
  }

  function unmount() {
    ReactDOM.unmountComponentAtNode(installWrapper);
    installWrapper.remove();
  }

  const installWrapper = document.createElement('div');
  installWrapper.id = 'canopy-native-app-prompt';
  document.body.appendChild(installWrapper);

  ReactDOM.render(<AppInstaller />, installWrapper);
}
