// This function is intended for devs and debugging. Not for actual usage in code.
if (localStorage.getItem('sofe-inspector')) {
  window.globalize = function(moduleName) {
    Promise
      .all([SystemJS.import('lodash'), SystemJS.import(moduleName)])
      .then(([_, module]) => {
        const globalVarName = _.camelCase(_.replace(moduleName, /\!.*/, ''));
        window[globalVarName] = module;
        /* eslint-disable */
        console.log(`'${globalVarName}' variable created`);
        /* eslint-enable */
      })
      .catch(err => console.error(err));
  }
} else {
  window.globalize = function() {
    throw new Error("window.globalize is a developer tool that should not be used in code. It is only accessible in the console when the sofe inspector is enabled")
  }
}
