exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-app-install-app-install-styles__root {\r\n  position: fixed;\r\n  bottom: 0px;\r\n  z-index: 100;\r\n  width: calc(100% - 56px);\r\n  margin: 28px;\r\n  background: rgb(255, 255, 255);\r\n  border-radius: 20px;\r\n  border: 1px solid rgb(233, 233, 233);\r\n  height: 152px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.src-app-install-app-install-styles__content {\r\n  height: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  font-size: 32px;\r\n  line-height: 38px;\r\n}\r\n\r\n.src-app-install-app-install-styles__bar {\r\n  border-radius: 0px 0px 24px 24px;\r\n  height: 24px;\r\n  width: 100%;\r\n  background: linear-gradient(\r\n    -225deg,\r\n    rgb(17, 92, 202) 0%,\r\n    rgb(12, 132, 220) 50%,\r\n    rgb(2, 212, 254) 100%\r\n  );\r\n}\r\n\r\n.src-app-install-app-install-styles__close {\r\n  margin: 0 42px;\r\n}\r\n\r\n.src-app-install-app-install-styles__action {\r\n  margin: 0 42px;\r\n  font-size: 48px;\r\n  color: var(--cps-color-blue);\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"root": "src-app-install-app-install-styles__root",
	"content": "src-app-install-app-install-styles__content",
	"bar": "src-app-install-app-install-styles__bar",
	"close": "src-app-install-app-install-styles__close",
	"action": "src-app-install-app-install-styles__action"
};