import * as isActive from '../child-app-active.functions.js';

export default async function appTitleListener() {
  window.addEventListener(
    'single-spa:routing-event',
    appTitleListener
  );

  function updateTitle(title) {
    document.title = 'Canopy - ' + title
  }

  function appTitleListener () {
    const location = window.location
    if (isActive.appDashboardUI(location)) {
      updateTitle("Home")
    } else if (isActive.canopyAdminUI(location)) {
      updateTitle("Admin")
    } else if (isActive.communicationsUI(location)) {
      updateTitle("Communication")
    } else if (isActive.tasksUI(location)) {
      updateTitle("Work")
    } else if (isActive.docsUI(location)) {
      updateTitle("Files")
    } else if (isActive.notesUI(location)) {
      updateTitle("Notes")
    } else if (isActive.engagementsUI(location)) {
      updateTitle("Engagements")
    } else if (isActive.taxPrepUI(location)) {
      updateTitle("Organizers")
    } else if (isActive.transcriptsUI(location)) {
      updateTitle("Transcripts")
    } else if (isActive.billingUI(location) && (isActive.hashPrefix(location, 'time') || isActive.containsRoutes(location, "#/client/:id/time"))) {
      updateTitle("Time")
    } else if (isActive.billingUI(location)) {
      updateTitle("Billing")
    } else if (isActive.lettersUI(location)) {
      updateTitle("Letters")
    } else if (isActive.noticesUI(location)) {
      updateTitle("Notices")
    } else if (isActive.notificationsUI(location)) {
      updateTitle("Notifications")
    } else if (isActive.contactMenu(location)) {
      updateTitle("Contact")
    } else if (isActive.contactsUI(location)) {
      updateTitle("Contacts")
    } else if (isActive.calendarUI(location)) {
      updateTitle("Calendar")
    } else if (isActive.activityLogUI(location)) {
      updateTitle("Activity")
    } else if (isActive.globalSettings(location)) {
      updateTitle("Settings")
    } else {
      document.title = "Canopy: Delightful Client Management"
    }
  }
}
