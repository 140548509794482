import { catchAsyncStacktrace } from 'auto-trace'

export async function fullStoryIdentify() {
  const authModule = await SystemJS.import('cp-client-auth!sofe');
  const { getLoggedInUserAsObservable } = authModule.default;

  getLoggedInUserAsObservable().subscribe(loggedInUser => {
    if (window.FS) {
      const { id, email, name, tenantId, licenses } = loggedInUser;
      window.FS.identify(id, { email, displayName: name, tenantId, licenses });
    }
  }, catchAsyncStacktrace());
}
