import {catchSyncStacktrace} from 'auto-trace';
import { calculatePublicPath } from "canopy-sofe-extensions";
import bootstrapSpa from './bootstrap/spa-bootstrapper.js';
import * as isActive from './child-app-active.functions.js';
import * as singleSpa from 'single-spa'
import { forkJoin, throwError, from, timer } from 'rxjs';
import { skipWhile, first, retryWhen, mergeMap } from 'rxjs/operators';
import { onlineListener, setupOnlineListener } from 'online-listener'

export { showAppInstall } from './app-install/app-install.helper.js';

__webpack_public_path__ = calculatePublicPath("spalpatine-ui");

if (window.singleSpa || window.commonDepsReady) {
  delete window.commonDepsReady
  init();
} else {
  window.addEventListener("spalpatine:common-deps-ready", commonDepsReady);
}

function commonDepsReady() {
  window.removeEventListener("spalpatine:common-deps-ready", commonDepsReady);
  init();
}

function init() {
  singleSpa.ensureJQuerySupport(window.jQuery);

  // add online and offline event handlers in order to show toasts and
  // handle intelligently reloading the app on reconnection
  setupOnlineListener()

  /* We want to declare the child applications as soon as possible so that they start loading as soon
   * as possible. However, we don't want their bootstrap or mount functions to be called until after
   * bootstrapSpa is finished.
   */
  // Main-content apps
  singleSpa.registerApplication('workflow-ui', loadAppWhenOnline('workflow-ui', () => SystemJS.import('workflow-ui!sofe')), isActive.workflowUI);
  singleSpa.registerApplication('end-user-forms-ui', loadAppWhenOnline('end-user-forms-ui', () => SystemJS.import('end-user-forms-ui!sofe')), isActive.endUserFormsUI);
  singleSpa.registerApplication('sme-qa-ui', loadAppWhenOnline('sme-qa-ui', () => SystemJS.import('sme-qa-ui!sofe')), isActive.smeQAUI);
  singleSpa.registerApplication('letters-ui', loadAppWhenOnline('letters-ui', () => SystemJS.import('letters-ui!sofe')), isActive.lettersUI);
  singleSpa.registerApplication('docs-ui', loadAppWhenOnline('docs-ui', () => SystemJS.import('docs-ui!sofe')), isActive.docsUI);
  singleSpa.registerApplication('notifications-ui', loadAppWhenOnline('notifications-ui', () => SystemJS.import('notifications-ui!sofe')), isActive.notificationsUI)
  singleSpa.registerApplication('communications-ui', loadAppWhenOnline('communications-ui', () => SystemJS.import('communications-ui!sofe')), isActive.communicationsUI);
  singleSpa.registerApplication('contacts-ui', loadAppWhenOnline('contacts-ui', () => SystemJS.import('contacts-ui!sofe')), isActive.contactsUI);
  singleSpa.registerApplication('tasks-ui', loadAppWhenOnline('tasks-ui', () => SystemJS.import('tasks-ui!sofe')), isActive.tasksUI);
  singleSpa.registerApplication('engagements-ui', loadAppWhenOnline('engagements-ui', () => SystemJS.import('engagements-ui!sofe')), isActive.engagementsUI)
  singleSpa.registerApplication('login-ui', loadAppWhenOnline('login-ui', () => SystemJS.import('login-ui!sofe')), isActive.loginUI);
  singleSpa.registerApplication('transcripts-ui', loadAppWhenOnline('transcripts-ui', () => SystemJS.import('transcripts-ui!sofe')), isActive.transcriptsUI);
  singleSpa.registerApplication('calendar-ui', loadAppWhenOnline('calendar-ui', () => SystemJS.import('calendar-ui!sofe')), isActive.calendarUI);
  singleSpa.registerApplication('notices-ui', loadAppWhenOnline('notices-ui', () => SystemJS.import('notices-ui!sofe')), isActive.noticesUI);
  singleSpa.registerApplication('billing-ui', loadAppWhenOnline('billing-ui', () => SystemJS.import('billing-ui!sofe')), isActive.billingUI);
  singleSpa.registerApplication('tax-prep-ui', loadAppWhenOnline('tax-prep-ui', () => SystemJS.import('tax-prep-ui!sofe')), location => isActive.taxPrepUI(location));
  singleSpa.registerApplication('app-dashboard-ui', loadAppWhenOnline('app-dashboard-ui', () => SystemJS.import('app-dashboard-ui!sofe')), isActive.appDashboardUI);
  singleSpa.registerApplication('canopy-admin-ui', loadAppWhenOnline('canopy-admin-ui', () => SystemJS.import('canopy-admin-ui!sofe')), isActive.canopyAdminUI);
  singleSpa.registerApplication('activity-log-ui', loadAppWhenOnline('activity-log-ui', () => SystemJS.import('activity-log-ui!sofe')), isActive.activityLogUI);
  singleSpa.registerApplication('notes-ui', loadAppWhenOnline('notes-ui', () => SystemJS.import('notes-ui!sofe')), isActive.notesUI)
  singleSpa.registerApplication('client-tax-returns', loadAppWhenOnline('tax-prep-ui', () => SystemJS.import('tax-prep-ui!sofe').then(mod =>
      mod.clientTaxReturnsHash)), isActive.clientTaxReturns,
  );

  // Supplementary apps
  singleSpa.registerApplication('primary-navbar', loadAppWhenOnline('primary-navbar', () => SystemJS.import('primary-navbar!sofe')), isActive.primaryNavbar);
  singleSpa.registerApplication('contact-menu', loadAppWhenOnline('contact-menu', () => SystemJS.import('contact-menu!sofe')), isActive.contactMenu);
  singleSpa.registerApplication('engagements-menu', loadAppWhenOnline('engagements-menu', () => SystemJS.import('engagements-menu!sofe')), isActive.engagementsMenu);
  singleSpa.registerApplication('canopy-urls', loadAppWhenOnline('canopy-urls', () => SystemJS.import('canopy-urls!sofe')), isActive.pageNotFound);
  singleSpa.registerApplication('global-settings', loadAppWhenOnline('global-settings', () => SystemJS.import('global-settings!sofe')), isActive.globalSettings);
  singleSpa.registerApplication('webview-ui', loadAppWhenOnline('webview-ui', () => SystemJS.import('webview-ui!sofe')), isActive.webviewUi)
  singleSpa.registerApplication('400', import('./400-pages/400.js'), isActive.fourHundred);

  // Mostly the loggedInUser, tenant, and websocketBootstrap
  bootstrapSpa()
    .then(() => {
      window.dispatchEvent(new CustomEvent("cp:app-loader-bootstrapped"));
      singleSpa.start()
      window.dispatchEvent(new CustomEvent("cp:app-loader:single-spa-start"));
    })
    .catch((err) => {
      catchSyncStacktrace(err);
    })
}

function loadAppWhenOnline(name, loader) {
  if (!name) throw new Error('Must have a name passed!');
  if (!loader) throw new Error(`Must have a loader passed for: ${name}`);

  return () => {
    return onlineListener
      .pipe(
        skipWhile(online => !online),
        first(),
        mergeMap(() => from(loader())),
        retryWhen(genericRetryStrategy(name)),
      )
      .toPromise();
  };
}

const genericRetryStrategy = (
  name,
  options = {
    maxRetryAttempts: 1,
    scalingDuration: 1000,
    excludedStatusCodes: []
  }
) => attempts => {
  return attempts.pipe(
    mergeMap((error, i) => {
      SystemJS.delete(SystemJS.normalizeSync(`${name}!sofe`));

      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > options.maxRetryAttempts ||
        options.excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      console.log( // eslint-disable-line
        `Attempt ${retryAttempt}: retrying ${name} in ${retryAttempt *
          options.scalingDuration}ms`
      );
      // retry after 1s, 2s, etc...
      return forkJoin(
        onlineListener.pipe(first()),
        timer(retryAttempt * options.scalingDuration)
      );
    })
  );
};

function loadAppWhenToggleEnabled(featureToggle, singleSpaLoadingFn) {
  if (typeof(featureToggle) !== 'string' || typeof(singleSpaLoadingFn) !== 'function') {
    throw new Error(`Cannot LoadAppByToggle without a featureToggle and a singleSpaLoadingFn`)
  }
  return SystemJS.import('feature-toggles!sofe').then(ft => ft.fetchFeatureToggles(featureToggle)).then(
    results => {
      if (results[featureToggle]) {
        return singleSpaLoadingFn()
      } else {
        return {
          bootstrap: () => Promise.resolve(),
          mount: () => Promise.resolve(),
          unmount: () => Promise.resolve(),
        }
      }
    }
  )
}
