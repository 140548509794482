export default function(loggedInUserPromise, tenantPromise) {
  return SystemJS.import('cp-client-auth!sofe')
    .then(cpClientAuth => {
      Promise
      .all([loggedInUserPromise, tenantPromise])
      .then(values => {
        const [user, tenant] = values;

        if (!user || !tenant) {
          return;
        }

        if (tenant.subscription_ended && !user.preferences.dismissedTrialExpiration) {
          // We need to navigate to a workflow-ui route so that they get the trial expiration modal.
          // Transcripts is the default home page for "freemium" users.
          window.singleSpa.navigateToUrl('/#/transcripts/list');
        }

        cpClientAuth = cpClientAuth.default;
        cpClientAuth.trackFreemiumUser(user);
      });
    });
}

