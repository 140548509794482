import isUserLoggedIn from './is-user-logged-in.bootstrap.js';
import {mainLoginPages, onLoginPage, hashPrefix} from '../child-app-active.functions.js';
import {isWithinLicenseLimit, hasAccess} from 'cp-client-auth!sofe';

const FREE_TRANSCRIPTS = "cp:free-transcripts";
let currentTenant
let currentLoggedInUser

window.addEventListener(
  'single-spa:routing-event',
  checkRootAppRedirect
);

export default function() {

  return isUserLoggedIn()
    .then(sessionIsValid => {
      if (sessionIsValid) {
        return Promise.all([
          SystemJS.import('cp-client-auth!sofe'),
          SystemJS.import('rxjs'),
          SystemJS.import('rxjs/operators')
        ]).then(values => {
          const [authModule, {pipe}, {first}] = values
          return Promise.all([
            authModule.default.getLoggedInUserAsObservable().pipe(first()).toPromise(),
            authModule.default.getTenantAsObservable().pipe(first()).toPromise()
          ])
            .then(([user, tenant]) => {
              currentLoggedInUser = user
              currentTenant = tenant
            })
            .then(checkLicenses)
        })
      } else {
        // The user is not logged in
        return Promise.all([
          Promise.resolve().then(() => {
            // Redirect to login page, but only if they aren't already on a login page
            if (!mainLoginPages(window.location)) {
              const encodedUrl = window.encodeURIComponent(window.location.href)
              const isMobileBrowser = /Mobi|Android/i.test(navigator.userAgent)
              const hash = window.location.hash;
              const params = (hash === '' || hash === '#' || hash === '#/') ? "" : `?redirect_url=${encodedUrl}`;
              if (isMobileBrowser) {
                window.location.replace(`m/login${params}`);
              } else {
                window.location.assign(`/#/login${params}`);
              }
            }
          }),
          Promise.all([
            SystemJS.import('cp-client-auth!sofe'),
            SystemJS.import('rxjs')
          ])
          .then(values => {

            const [authModule, {combineLatest}] = values

            const userAndTenant$ = combineLatest(
              authModule.default.getLoggedInUserAsObservable(),
              authModule.default.getTenantAsObservable()
            )

            userAndTenant$.subscribe(
              ([user, tenant]) => {
                currentLoggedInUser = user
                currentTenant = tenant
                checkLicenses()
              }
            )
          })
        ])
      }
    })
}

function checkLicenses() {
  if (!currentLoggedInUser || !currentTenant || mainLoginPages(window.location)) {
    return;
  }

  if (currentLoggedInUser.is_deleted) {
    window.location.hash = `#/logged-in-user-deleted`;
  }

  const invalidLicenses = getInvalidLicenses(currentTenant, currentLoggedInUser);
  const onLicenseManagementPage = hashPrefix(window.location, 'global-settings/account-management')
  const hasClosedModal = !!window.localStorage.getItem('global-settings:license-paywall-modal-closed')

  if (invalidLicenses && !onLicenseManagementPage && !hasClosedModal) {
    // remove the node-bootstrap-server loader
    const loader = document.querySelector('.initial-node-bootstrap-server-loader')
    if (loader) {
      loader.remove()
    }

    return SystemJS.import('global-settings!sofe')
      .then(settings => settings.getLicensePaywallModal(invalidLicenses, checkRootAppRedirect));
  }

  checkRootAppRedirect();
}

/**
 * If we are at the root route, redirect the user to the root app according to their permissions
 */
function checkRootAppRedirect() {
  if (!currentLoggedInUser || !currentTenant) {
    return;
  }
  const hash = window.location.hash;

  if (currentLoggedInUser.role.toLowerCase() === 'client' && !onLoginPage(window.location)) {
    if (hash === '' || hash === '#' || hash === '#/') {
      return window.location = `${window.location.origin}/m/clients`
    } else {
      // Building out some query params based on the current url
      // to redirect the user to the correct route on CP2.0.
      // This can be cleaned up when CP1.0 notifications are no longer valid.
      const routes = hash.split('?')[0].split('/')
      const possibleMatches = [
        'clients',
        'client',
        'engagements',
        'requests',
        'survey',
        'surveys',
        'esign',
        'payments',
        'files',
        'billing',
        'receipt',
      ]
      const params = new URLSearchParams(hash.split('?')[1] || '')
      let clientId = null
      possibleMatches.forEach(e => {
        const val = routes?.findIndex(route => route === e)
        if (val < 0) return
        const boolRoutes = ['billing', 'files', 'receipt', 'surveys']
        if (boolRoutes.includes(e) || (e === 'request' && routes.length <= val + 1)) {
          return params.set(e, true)
        }
        if (routes.length > val + 1) {
          if (e.includes('client')) {
            return clientId = routes[val + 1]
          } else {
            return params.set(e, routes[val + 1])
          }
        }
      })

      const redirectUrl = new URL(`${window.location.origin}/m/clients${clientId ? `/${clientId}` : ''}`)
      redirectUrl.search = params.toString()
      window.location.href = redirectUrl.href
    }
  } else {
    if (hash === '' || hash === '#' || hash === '#/') {
      const {app_dashboard, contacts} = currentLoggedInUser.permissions
      if (app_dashboard) {
        // App dashboard ui (owns the /home route) handles rerouting based on permissions
        window.location.hash = '#/home'
      } else if (contacts) {
        window.location.hash = '#/clients';
      } else {
        window.location.hash = '#/transcripts/list';
      }
    }
  }
}

function isExpired(tenant) {
  return !tenant.is_active;
}

function getInvalidLicenses(tenant, user) {
  if (!hasAccess(user)('company_settings_account_management') || user.role === 'Client') {
    // User doesn't have the correct permissions, so don't check licenses
    return;
  }

  const licenses = {...tenant.licenses};

  const invalidLicenseNames = Object.keys(licenses)
  .filter(type => !isWithinLicenseLimit(type, licenses, tenant.contact_limit, tenant.free_transcripts));

  if (!invalidLicenseNames.length) return;

  const invalidLicenses = invalidLicenseNames.reduce((invalidLicenses, licenseName) => {
    return {
      ...invalidLicenses,
      [licenseName]: licenses[licenseName]
    };
  }, {});

  return invalidLicenses;
}
