import {syncStacktrace} from 'auto-trace'

const appLoaderExecutedTimestamp = new Date().getTime();

export { waitForEvent };

export default function(loggedInUserPromise, tenantPromise) {
  let childAppsDeclared, singleSpaStart, firstMount, fetcherModule;

  return Promise
    .all([
      SystemJS.import('cp-client-auth!sofe'),
      SystemJS.import('rxjs'),
      SystemJS.import('rxjs/operators'),
      waitForEvent('cp:app-loader-bootstrapped'),
      waitForEvent('cp:app-loader:single-spa-start'),
      waitForEvent('single-spa:first-mount'),
      SystemJS.import('fetcher!sofe')
    ])
    .then(values => {
      const [
        cpClientAuth,
        {pipe},
        {first},
        ...rest
      ] = values

      // these are accessed this way because we store these values in the closure above
      childAppsDeclared = rest[0]
      singleSpaStart = rest[1]
      firstMount = rest[2]
      fetcherModule = rest[3]

      return Promise
        .all([
          cpClientAuth.default.getLoggedInUserAsObservable().pipe(first()).toPromise(),
          cpClientAuth.default.getTenantAsObservable().pipe(first()).toPromise()
        ])
    })
    .then(authData => {
      const [loggedInUser, tenant] = authData;

      if (!loggedInUser || !tenant) {
        return;
      }

      const optionalData = {
        userId: loggedInUser.id,
        email: loggedInUser.email,
        tenantId: tenant.id,
        app_loader_release: window.appLoaderVersion,
        beginning_time: window.serverInitialTimestamp,
        first_sofe_service: window.firstSofeServiceLoaded - window.appLoaderInitialTime,
        logged_in_user_loaded: window.loggedInUserTime,
        tenant_loaded: window.tenantTime,
        app_loader_loaded: appLoaderExecutedTimestamp - window.appLoaderInitialTime,
        child_apps_declared: childAppsDeclared - window.appLoaderInitialTime,
        single_spa_start: singleSpaStart - window.appLoaderInitialTime,
        first_app_mounted: firstMount - window.appLoaderInitialTime,
        browser: detectBrowser(navigator.userAgent).name,
        userAgent: navigator.userAgent,
        url: window.location.href,
        resolution: `${window.screen.width}x${window.screen.height}`,
        resolution_width: window.screen.width,
        resolution_height: window.screen.height
      };

      window.appLoaderPerf = optionalData;

      if (loggedInUser.email && loggedInUser.email.includes('@canopytax.com') && !localStorage.getItem('cp:record-perf')) {
        return Promise.resolve();
      } else {
        return fetcherModule.default(`/tracked-usage`, {
          method: 'POST',
          body: {
            category: 'spalpatine_perf',
            action: 'app_initialized',
            optional: optionalData,
          },
        });
      }
    })
    .then(resp => {
      delete window.tenantTime;
      delete window.loggedInUserTime;
      delete window.firstSofeServiceLoaded;
      delete window.serverInitialTimestamp;
      if (resp && !resp.ok) {
        throw syncStacktrace(resp);
      }
    })
    .catch(err => {
      if (err && err.spalpatinePerfMetrics) {
        // Just capture this error in sentry without showing a toast to the user.
        return SystemJS.import('sentry-error-logging!sofe')
          .then(sentry => {
            err.showToast = false;
            sentry.captureException(err, {level: 'info'});
          });
      } else if (err && err.message === 'cancelled') {
        // Safari 12.1 is cancelling the `/tracked-usage` subscription upon login (when the login page is being refreshed)
        return Promise.resolve();
      } else {
        // Rethrow to be handled by the code that called this function
        throw syncStacktrace(err);
      }
    });
}

function waitForEvent(eventName) {
  return new Promise((resolve, reject) => {
    window.addEventListener(eventName, listener);

    function listener() {
      window.removeEventListener(eventName, listener);
      resolve(new Date().getTime());
    }
  });
}

function detectBrowser(userAgentString) {
  if (!userAgentString) return null;

  var browsers = [
    [ 'edge', /Edge\/([0-9\._]+)/ ],
    [ 'yandexbrowser', /YaBrowser\/([0-9\._]+)/ ],
    [ 'chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/ ],
    [ 'crios', /CriOS\/([0-9\.]+)(:?\s|$)/ ],
    [ 'firefox', /Firefox\/([0-9\.]+)(?:\s|$)/ ],
    [ 'opera', /Opera\/([0-9\.]+)(?:\s|$)/ ],
    [ 'opera', /OPR\/([0-9\.]+)(:?\s|$)$/ ],
    [ 'ie', /Trident\/7\.0.*rv\:([0-9\.]+)\).*Gecko$/ ],
    [ 'ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/ ],
    [ 'ie', /MSIE\s(7\.0)/ ],
    [ 'bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/ ],
    [ 'android', /Android\s([0-9\.]+)/ ],
    [ 'ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/ ],
    [ 'safari', /Version\/([0-9\._]+).*Safari/ ]
  ];

  const finalBrowser = browsers.map(function (rule) {
    if (rule[1].test(userAgentString)) {
      var match = rule[1].exec(userAgentString);
      var version = match && match[1].split(/[._]/).slice(0,3);

      if (version && version.length < 3) {
        Array.prototype.push.apply(version, (version.length == 1) ? [0, 0] : [0]);
      }

      return {
        name: rule[0],
        version: version.join('.')
      };
    }
  }).filter(Boolean).shift();

  if(finalBrowser && finalBrowser.name && finalBrowser.version) return finalBrowser
  return {
    name: "unknown",
    version: "unknown"
  }
}
