import {catchSyncStacktrace} from 'auto-trace';

export default async function delightedSurveyBootstrap() {
    try {
        const hasPurchasedLicensePromise = SystemJS.import('cp-client-auth!sofe').then(({hasPurchasedLicense}) => hasPurchasedLicense)

        const [hasPurchasedLicense] = await Promise.all([hasPurchasedLicensePromise]);
        if (window.delightedNps && window.loggedInUser && window.loggedInUser.role === "TeamMember" && window.tenant && isPayingCustomer(hasPurchasedLicense, window.tenant)) {
            const userCreatedAtIso = new Date(window.loggedInUser.created_at).toISOString()
            window.delightedNps.survey({
                email: window.loggedInUser.email,
                name: window.loggedInUser.name,
                createdAt: userCreatedAtIso,
                minTimeOnPage: 60,
                properties: {
                    tenant_id: window.tenant.id,
                    directory_user_id: window.loggedInUser.id,
                    company: window.tenant.company_name
                }
            });
        }
    } catch (e) {
        catchSyncStacktrace(e)
    }
}

function isPayingCustomer(hasPurchasedLicense, tenant) {
    const licenses = tenant.licenses;
    const contactLimit = tenant.contact_limit;
    const freeTranscripts = tenant.free_transcripts;
    const isPayingPracticeManagementCustomer = hasPurchasedLicense('practiceManagement', licenses, contactLimit, freeTranscripts);
    return isPayingPracticeManagementCustomer || (!!licenses && Object.keys(licenses).some(type => hasPurchasedLicense(type, licenses, contactLimit, freeTranscripts)));
}