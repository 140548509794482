import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

export default async function bannerNotificationsBootstrap() {
  const { getBannerNotificationsController } = await SystemJS.import('notifications-ui!sofe')
  const BannerNotificationsController = lazy(async () => await getBannerNotificationsController())

  const container = document.createElement('div')
  container.id = 'banner-notifications-container'
  document.body.appendChild(container)

  ReactDOM.render(
    <Suspense fallback={<div />}>
      <BannerNotificationsController />
    </Suspense>
  , container)
}
