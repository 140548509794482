export default function() {
  return Promise.all([SystemJS.import('sofe'), SystemJS.import('sentry-error-logging!sofe'), SystemJS.import('cp-client-auth!sofe')])
    .then(([sofe, sentryErrorLogging, auth]) => {
      return sofe
        .getAllManifests()
        .then(manifest => {
          if (!window.appLoaderVersion) {
            throw new Error(`Cannot initialize sentry without window.appLoaderVersion`);
          }

          sentryErrorLogging.init(`app-loader-ui_${window.appLoaderVersion}`, null, null, manifest.flat);

          /* This observable never should call onCompleted() or onError(), so normally we would need to dispose of our subscription
           * when we are done with it to avoid memory leaks. However, in this case we are never done with it until the page reloads
           * and the memory is cleaned up anyway.
           */
          auth.default.getLoggedInUserAsObservable().subscribe(
            user => sentryErrorLogging.setUser(user)
          )
        })
    })
}
