import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

export default async function mailtoListener() {
  const authModule = await SystemJS.import('cp-client-auth!sofe')
  const { getComposeEmailOverlay } = await SystemJS.import('communications-ui!sofe')

  const ComposeEmail = lazy(async () => await getComposeEmailOverlay());
  const { getLoggedInUserAsObservable, hasAccess } = authModule.default

  // track modal open state to prevent multiple modals from opening and to prevent
  // nested modals if a mailto link is added to the modal's Rich Text Editor
  let modalOpen = false
  getLoggedInUserAsObservable().subscribe(loggedInUser => {
    function clickListener(event) {
      const canUserSendEmails = loggedInUser.role !== "Client" && hasAccess(loggedInUser)('communications')

      if (!modalOpen && canUserSendEmails) {
        const mailto = event?.target?.closest?.('a[href^=mailto]')
        if (mailto) {
          event.preventDefault()

          modalOpen = true

          const email = new URL(mailto.href).pathname
          const container = document.createElement('div')
          document.body.appendChild(container)

          ReactDOM.render(
            <Suspense fallback={<div />}>
              <ComposeEmail
                to={email}
                close={() => {
                  modalOpen = false
                  ReactDOM.unmountComponentAtNode(container)
                }}
                source="Email Hyperlink"
              />
            </Suspense>
          , container)
        }
      }
    }

    window.addEventListener('click', clickListener, true)

    return () => {
      modalOpen = false
      window.removeEventListener('click', clickListener, true)
    }
  })
}
