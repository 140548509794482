export default function() {
  return Promise.all([
    SystemJS.import('cp-client-auth!sofe'),
    SystemJS.import('rxjs/operators')
  ])
    .then(values => {
      const [authModule, {first}] = values
      return Promise.all([
        authModule.default.getLoggedInUserAsObservable().pipe(first()).toPromise(),
        authModule.default.getTenantAsObservable().pipe(first()).toPromise()
      ])

    })
    .then(values => {
      const [loggedInUser, tenant] = values;
      if (!loggedInUser.effective_permissions.client && !localStorage.getItem("no-product-tracking")) {
        return setIntercomSettings(tenant, loggedInUser)
          .then(loadIntercom)
          .then(() => {
            // https://docs.intercom.com/install-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
            window.addEventListener('hashchange', updateIntercom);
          })
      }
    })
}

function setIntercomSettings(tenant, loggedInUser) {
  const createdAtWithoutMillis = (loggedInUser.created_at / 1000).toString();
  const billingEndsAtWithoutMillis = tenant.license_expires / 1000;

  return getIntercomAppId().then((app_id) => {
    window.intercomSettings = {
      name: loggedInUser.name,
      user_id: loggedInUser.id,
      email: loggedInUser.email,
      created_at: createdAtWithoutMillis,
      company_id: tenant.id,
      app_id,
      user_type: 'TeamMember',
      admin: !!loggedInUser.effective_permissions.superuser,
      billing_ends_at: billingEndsAtWithoutMillis,
      custom_launcher_selector: '#canopy-intercom-launcher',
      hide_default_launcher: true, // This hides the intercom bubble forever
    };
  })
}

function getIntercomAppId() {
  // async load sofe services for perf
  return SystemJS
    .import('canopy-urls!sofe')
    .then(canopyUrls => {
      canopyUrls = canopyUrls.default;

      const environment = canopyUrls.getEnvironment();
      switch (environment) {
        case canopyUrls.DEVELOPMENT_ENVIRONMENT:
        case canopyUrls.STAGING_ENVIRONMENT:
        case canopyUrls.INTEGRATION_ENVIRONMENT:
          return "hjwihh7";
          // This is an old app id that was used for test envs but doesn't seem to anymore
          // return "m9eosqne";
        case canopyUrls.PRODUCTION_ENVIRONMENT:
          return "hjwihh7";
        default:
          throw new Error(`Unknown environment ${environment}`);
      }
    })
}

function loadIntercom() {
  /* eslint-disable */
  var w=window;var ic=w.Intercom;
  if(typeof ic==="function"){
    ic('reattach_activator');
    ic('update',window.intercomSettings);
  } else {
    var d=document;
    var i=function(){
      i.c(arguments)
    };
    i.q=[];
    i.c=function(args){
      i.q.push(args)
    };
    w.Intercom=i;
    var l = function(){
      getIntercomAppId()
        .then(appId => {
          var s=d.createElement('script');
          s.type='text/javascript';
          s.async=true;
          s.src=`https://widget.intercom.io/widget/${appId}`;
          s.onload = () => resizeIntercom(0);
          var x=d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s,x);
        })
        .catch(err => {
          throw err;
        });
    }
    if (document.readyState !== 'complete') {
      if(w.attachEvent){
        w.attachEvent('onload',l);
      } else {
        w.addEventListener('load',l,false);
      }
    } else {
      l();
    }
  }
  /* eslint-enable */
}

function updateIntercom() {
  try {
    if (window.Intercom) {
      window.Intercom('update');
    }
  } catch(err) {
    /* Swallow. If Intercom is dying, then that's it's own fault.
     * See https://sentry.canopytax.com/canopy/frontend-production-1/issues/18048/
     * for why we don't want to log this.
     */
  }
}

function resizeIntercom(numRetries=0) {
  const intercomIframe = document.querySelector(`#intercom-container .intercom-launcher-frame`);
  if (!intercomIframe) {
    /* Intercom loads lots of iframes and so we don't actually have an event for when it's fully loaded.
     * So we poll the dom for four seconds to wait for it to load. If it isn't loaded at that point, we just
     * give up on resizing the intercom bubble.
     */
    if (numRetries < 40) {
      setTimeout(() => resizeIntercom(numRetries + 1), 100)
    }
    return;
  }

  // setProperty is necessary in order to make it !important
  intercomIframe.style.setProperty('bottom', '10px', 'important');
  intercomIframe.style.setProperty('right', '10px', 'important');
  intercomIframe.style.setProperty('height', '45px', 'important');
  intercomIframe.style.setProperty('width', '45px', 'important');

  const intercomLauncher = intercomIframe.contentDocument.querySelector('.intercom-launcher');
  if (intercomLauncher) {
    intercomLauncher.style.width = `45px`;
    intercomLauncher.style.height = `45px`;
    const openIcon = intercomLauncher.querySelector(`.intercom-launcher-open-icon`);
    if (openIcon) {
      openIcon.style.backgroundSize = `24px 27px`;
      openIcon.style.backgroundPosition = `center 10.5px`;
    }
  }

  window.Intercom('onUnreadCountChange', repositionNotificationBadge);
  window.Intercom('onHide', repositionNotificationBadge);

  window.Intercom('onShow', () => {
    /* This makes the messages from intercom appear directly above the smaller bubble, taking into account the resizing.
     * We have to setTimeout because when the onShow event is fired, the dom element isn't actually created yet :(
     */
    setTimeout(() => {
      const messengerFrame = document.querySelector(`#intercom-container .intercom-app-launcher-enabled .intercom-messenger-frame`);
      if (messengerFrame) {
        messengerFrame.style.setProperty('bottom', '70px', 'important');
      }
    })
    repositionNotificationBadge();
  });

  function repositionNotificationBadge() {
    // setTimeout because intercom calls this right before it actually creates dom elements
    setTimeout(() => {
      const badgeIframe = document.querySelector('#intercom-container .intercom-launcher-discovery-frame');
      if (badgeIframe) {
        badgeIframe.style.setProperty('bottom', '11px', 'important');
        badgeIframe.style.setProperty('width', '50px', 'important');
        badgeIframe.style.setProperty('height', '50px', 'important');
        badgeIframe.style.setProperty('right', '11px', 'important');
      }
    });
  }
}

let intercomStyleEl;

function hideIntercomBubble() {
  /* Walkme is going to hide the intercom bubble anyway, but it does that after the intercom
   * bubble is already visible. We don't want the intercom bubble to ever be visible
   */
  intercomStyleEl = document.createElement('style');
  intercomStyleEl.textContent = `
    #intercom-container .intercom-app .intercom-launcher-frame, #intercom-container .intercom-app .intercom-launcher-discovery-frame {
      display: none !important;
    }
  `;

  document.head.appendChild(intercomStyleEl);
}
