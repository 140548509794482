import { datadogRum } from '@datadog/browser-rum';

export function dataDogRum() {
  return SystemJS.import('canopy-urls!sofe').then(canopyUrls => {
    const { getEnvironment, getWebUrl } = canopyUrls.default;
    const env = getEnvironment();
    const mainUrl = getWebUrl();
    datadogRum.init({
      applicationId: 'f1ff9217-df5c-4f6f-a69a-158428645539',
      clientToken: 'pub2052c4def7e90a0242e1fef62a03c32c',
      site: 'datadoghq.com',
      env,
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [mainUrl],
    });
  });
}
